import './style.css'
import jpg from '../assets/images/artwork1.jpg'

import pcMain from '../assets/images/main-pc.jpg'
import spMain from '../assets/images/main-sp.jpg'
import * as THREE from 'three'
import * as dat from 'dat.gui'

import soundfile from '../assets/audio/bgm.mp3'
import {
    GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader.js'
import {
    Reflector
} from 'three/examples/jsm/objects/Reflector.js'
import {
    PointerLockControls
} from './PointerlockcontrolsMobile'

import {
    gsap
} from "gsap";
import {
    TweenMax,
    TimelineMax,
    Elastic,
    Expo
} from "gsap";

import {
    SplitText
} from "gsap/SplitText";
import {
    TextureLoader
} from 'three/build/three.module'
import {
    FrontSide
} from 'three'


gsap.registerPlugin(SplitText);
//  const gui = new dat.GUI()
//animation

let hPara = document.getElementById('instructionsPara')
let hPara2 = document.getElementById('instructionsPara2')
let hPara3 = document.getElementById('instructionsPara3')

var tl = new TimelineMax({
        onStart: onStartfunction,

    }),
    mySplitText = new SplitText("#titleText", {
        type: "words,chars"
    }),
    chars = mySplitText.chars;
gsap.set("#titleText", {
    perspective: 200
})

function onStartfunction() {
    console.log('start')
}

tl.from(chars, {
        duration: 1.2,
        opacity: 0,
        scale: 0,
        y: 50,
        rotationX: 40,
        transformOrigin: "0% 50% -50",
        ease: "back(4)",
        stagger: 0.05
    }, "+=0"),
    tl.fromTo(
        hPara,
        1.2, {
            skewY: -10,
            y: 100,
            autoAlpha: 0
        }, {
            skewY: 0,
            y: 0,
            autoAlpha: 1,
            ease: Expo.easeOut
        }, "-=.8"),

    tl.fromTo(
        hPara2,
        1.6, {
            x: -30,
            autoAlpha: 0
        }, {

            x: 0,
            autoAlpha: 1,
            ease: Expo.easeOut
        }, "-=.8"),
    tl.fromTo(
        hPara3,
        1.6, {
            x: -30,
            autoAlpha: 0
        }, {
            x: 0,
            autoAlpha: 1,
            ease: Expo.easeOut
        }, "-=1")
tl.paused(true)


// audio

let audio = new Audio(soundfile); // audioの作成
let audioOn = document.querySelectorAll('#audioOn')
let audioOff = document.querySelectorAll('#audioOff')
let audioPlaying = document.querySelector('.audioWave')
let audioPlayingSp = document.querySelector('#wave')
audioPlaying.style.display = 'none'

for (let i = 0; i < audioOn.length; i++) {
    audioOn[i].addEventListener('click', function () {
        // audio.src = '/assets/audio/bgm.mp3'; // 音声ファイルの指定
        audio.loop = true
        audio.load(); // audioの読み込み
        audio.play()
        audioOn[i].style.color = "white"
        audioOff[i].style.color = "#c4c4c4"
        audioPlaying.style.display = ''
        audioPlayingSp.style.visibility = ''
    })
}
for (let i = 0; i < audioOn.length; i++) {
    audioOff[i].addEventListener('click', function () {
        // audio.src = '/assets/bgm.mp3'; // 音声ファイルの指定
        audio.load(); // audioの読み込み
        audio.muted == true
        audioOff[i].style.color = "white"
        audioOn[i].style.color = "#c4c4c4"
        audioPlaying.style.display = 'none'
        audioPlayingSp.style.visibility = 'hidden'

    })
}


//img1
let imgJpg = document.createElement('img')
imgJpg.src = jpg
document.getElementById('img1').appendChild(imgJpg)

//img2
let rVideo = document.createElement('video')
rVideo.src = "textures/video/rhizome.mp4"
rVideo.loop = true
rVideo.autoplay = true
rVideo.setAttribute('playsinline', '');
rVideo.muted = true
rVideo.controls = true
document.getElementById('img2').appendChild(rVideo)

function videocheck() {
    if (rVideo.muted == true && audio.muted == false) {
        // console.log("video starts&audiostopped")
        audio.muted = true
        rVideo.muted = false
        // } else if (rVideo.muted == true && audio.muted !== false) {
        //     rVideo.muted == true
        // }
    }
        

    }


    function videoend() {
        // console.log("video end")
        rVideo.muted = true
        audio.muted = false
    }
    //メインビジュアルPC
    let imgMain = document.createElement('img')
    imgMain.src = pcMain
    imgMain.style.width = "100%"
    imgMain.style.height = '100%'

    if (!isMobile == true) {
        window.addEventListener('load', function () {
            document.getElementById('blocker').prepend(imgMain)
        })
        document.getElementById('blocker').prepend(imgMain)
    }
    // //メインビジュアルSP
    let imgSpMain = document.createElement('img')
    imgSpMain.src = spMain
    imgSpMain.style.width = "100%"
    imgSpMain.style.height = '100%'
    imgSpMain.style.zIndex = '10'
    imgSpMain.style.position = 'absolute'

    if (isMobile == true) {
        document.getElementById('blocker').appendChild(imgSpMain)

    }


    /**
     * Loaders
     */
    const gltfLoader = new GLTFLoader()
    const gltfLoader2 = new GLTFLoader()
    const cubeTextureLoader = new THREE.CubeTextureLoader()
    const textureLoader = new THREE.TextureLoader()



    /**
     * Base
     */
    // Debug
    // const gui = new dat.GUI()
    const debugObject = {}
    const objects = []
    const popUpObjects = []
    const textMesh = []

    let camera, scene, renderer, controls;
    let raycaster;
    let raycaster2
    let backWard
    let moveForward = false;
    let moveBackward = false;
    let moveLeft = false;
    let moveRight = false;
    let canJump = false;
    let point = document.querySelectorAll('.point')
    let prevTime = performance.now();
    let model = new THREE.Object3D();
    const velocity = new THREE.Vector3();
    const direction = new THREE.Vector3();
    // const vertex = new THREE.Vector3();
    // const color = new THREE.Color();
    const canvas = document.querySelector('blocker')



    init();
    animate();

    function init() {



        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1000);
        camera.position.y = 30;

        // シーンを開始
        scene = new THREE.Scene();

        //  ライト設定
        const hemisphereLight = new THREE.HemisphereLight(0xFDC8C2, 0x939ef6, 1);
        // gui.add(hemisphereLight, 'intensity').min(0).max(10).step(0.001).name('hemispherelightIntensity')
        hemisphereLight.position.set(0.5, 0, 1);
        scene.add(hemisphereLight);
        //popUp用のボックスを設定

        const fontLoader = new THREE.FontLoader()


        // // ポイントライト１
        const pointLight1 = new THREE.PointLight(0xF5E0FC, 2.5, 20, 1);
        pointLight1.position.set(50, 65, -140)
        pointLight1.shadow.mapSize.set(1024, 1024)
        pointLight1.shadow.normalBias = 0.05
        pointLight1.castShadow = true
        scene.add(pointLight1);

        // gui.add(pointLight1, 'intensity').min(0).max(120).step(0.001).name('pointlight1Intensity')
        // gui.add(pointLight1.position, 'x').min(-500).max(500).step(10).name('pointlight1X')
        // gui.add(pointLight1.position, 'y').min(0).max(100).step(10).name('pointlight1Y')
        // gui.add(pointLight1.position, 'z').min(-200).max(20).step(10).name('pointlight1Z')

        // // ポイントライト2
        const pointLight2 = new THREE.PointLight(0xF5E0FC, 2.5, 20, 1);
        pointLight2.position.set(320, 65, -790)
        pointLight2.shadow.mapSize.set(1024, 1024)
        pointLight2.shadow.normalBias = 0.05
        pointLight2.castShadow = true
        scene.add(pointLight2);

        // gui.add(pointLight2, 'intensity').min(0).max(120).step(0.001).name('pointligh2tIntensity')
        // gui.add(pointLight2.position, 'x').min(-500).max(500).step(10).name('pointlight2X')
        // gui.add(pointLight2.position, 'y').min(0).max(100).step(10).name('pointlight2Y')
        // gui.add(pointLight2.position, 'z').min(-50).max(500).step(10).name('pointlight21Z')

        // // ポイントライト3
        const pointLight3 = new THREE.PointLight(0xF5E0FC, 2.5, 20, 1);
        pointLight3.position.set(-360, 65, -700)
        pointLight3.shadow.mapSize.set(1024, 1024)
        pointLight3.shadow.normalBias = 0.05
        pointLight3.castShadow = true
        scene.add(pointLight3);
        // const pointLightHelper3 = new THREE.PointLightHelper(pointLight3, 10);
        // scene.add(pointLightHelper3);
        // gui.add(pointLight3, 'intensity').min(0).max(120).step(0.001).name('pointlight3Intensity')
        // gui.add(pointLight3.position, 'x').min(-10004).max(1000).step(10).name('pointlight3X')
        // gui.add(pointLight3.position, 'y').min(0).max(100).step(10).name('pointlight3Y')
        // gui.add(pointLight3.position, 'z').min(-1000).max(1000).step(10).name('pointlight3Z')



        //video用箱
        // const video = document.createElement('video');
        // video.autoplay = true;
        // video.loop =true
        // video.muted = true
        // video.src = "textures/video/rhizome.mp4";

        const videoTexture = new THREE.VideoTexture(rVideo);
        videoTexture.minFilter = THREE.LinearFilter;
        videoTexture.magFilter = THREE.LinearFilter;
        videoTexture.format = THREE.RGBFormat;
        videoTexture.needsUpdate = true;
        videoTexture.flipY = false

        // const videoMaterial = new THREE.MeshPhysicalMaterial({
        //     map: videoTexture,
        //     metalness: 0,
        //     reflectivity: 1.0,
        //     roughness: .1,
        //     transmission: .3,
        //     opacity: 1,
        //     ior: 1.333,
        //     side:FrontSide

        // })
        //Create screen
        // const screen = new THREE.PlaneGeometry(45, 80, 2)
        // const videoScreen = new THREE.Mesh(screen, videoMaterial);
        // videoScreen.position.x = 140
        // videoScreen.position.y = 45
        // videoScreen.position.z = -883
        // videoScreen.rotation.y = -Math.PI 
        // scene.add(videoScreen);

        // // Info箱
        const boxInfoGeometry = new THREE.BoxGeometry(120, 100, 20).toNonIndexed();
        const boxInfoMaterial = new THREE.MeshStandardMaterial({
            side: THREE.DoubleSide
        });
        const boxInfo = new THREE.Mesh(boxInfoGeometry, boxInfoMaterial);
        boxInfo.position.x = 58;
        boxInfo.position.y = 30
        boxInfo.position.z = -180
        boxInfo.rotation.y = 3.46
        boxInfo.visible = false
        scene.add(boxInfo);
        // gui.add(boxInfo.position,'x').min(-1000).max(1000).step(10).name('box2x')
        // gui.add(boxInfo.position,'z').min(-1000).max(1000).step(10).name('box2z')
        // gui.add(boxInfo.rotation,'y').min(-3).max(3).step(.1).name('box2rotate')

        // // 絵１箱
        const box1Geometry = new THREE.BoxGeometry(40, 100, 160).toNonIndexed();
        const box1Material = new THREE.MeshPhongMaterial({

        });

        const box1 = new THREE.Mesh(box1Geometry, box1Material);
        box1.position.x = 360;
        box1.position.y = 30;
        box1.position.z = -530;
        box1.rotation.y = -Math.PI
        box1.visible = false
        scene.add(box1);


        //絵２用箱
        const box2Geometry = new THREE.BoxGeometry(40, 100, 120).toNonIndexed();
        const box2Material = new THREE.MeshPhongMaterial({

        });

        const box2 = new THREE.Mesh(box2Geometry, box2Material);
        box2.position.x = 140;
        box2.position.y = 30;
        box2.position.z = -890;
        box2.rotation.y = -Math.PI
        box2.visible = false
        scene.add(box2);

        popUpObjects.push(boxInfo, box1, box2)
        objects.push(boxInfo, box1, box2)
        // console.log(popUpObjects)
        //particle


        // ポインターロック設定
        controls = new PointerLockControls(camera, document.body);
        const blocker = document.getElementById('blocker');
        const enterBtn = document.getElementById('entryBtn');
        const instructions = document.getElementById('instructions');

        enterBtn.addEventListener('click', function () {
            controls.lock();

        }, false);


        controls.addEventListener('lock', function () {

            instructions.style.display = 'none';
            blocker.style.display = 'none';

        });

        controls.addEventListener('unlock', function () {

            blocker.style.display = 'block';
            instructions.style.display = '';

        });

        scene.add(controls.getObject());



        const onKeyDown = function (event) {

            switch (event.keyCode) {

                case 38:
                case 87:
                    moveForward = true;
                    break;

                case 37:
                case 65:
                    moveLeft = true;
                    break;

                case 40:
                case 83:
                    moveBackward = true;
                    break;

                case 39:
                case 68:
                    moveRight = true;
                    break;

                case 32:
                    if (canJump === true) velocity.y += 350;
                    canJump = false;
                    break;

            }


        };

        const onKeyUp = function (event) {

            switch (event.keyCode) {

                case 38:
                case 87:
                    moveForward = false;
                    break;

                case 37:
                case 65:
                    moveLeft = false;
                    break;

                case 40:
                case 83:
                    moveBackward = false;
                    break;

                case 39:
                case 68:
                    moveRight = false;
                    break;

            }

        };

        // スマホ前進
        const forward = document.getElementById('controller-forward')
        forward.addEventListener("touchstart", function () {

            moveForward = true;
        })
        forward.addEventListener("touchend", function () {

            moveForward = false;
        })

        // スマホ右
        const right = document.getElementById('controller-right')
        right.addEventListener("touchstart", function () {
            moveRight = true;
        })
        right.addEventListener('touchend', function () {
            moveRight = false
        })

        // スマホ左
        const left = document.getElementById('controller-left')
        left.addEventListener("touchstart", function () {
            moveLeft = true;
        })
        left.addEventListener('touchend', function () {
            moveLeft = false
        })

        // スマホ後ろ
        const back = document.getElementById('controller-back')
        back.addEventListener("touchstart", function () {
            moveBackward = true;
        })
        back.addEventListener('touchend', function () {
            moveBackward = false
        })

        document.addEventListener('keydown', onKeyDown, false);
        document.addEventListener('keyup', onKeyUp, false);

        raycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, -1, 0), 0, 10);
        raycaster2 = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(0, -1, 0), 0);
        /**
         * Update all materials
         */
        const updateAllMaterials = () => {
            scene.traverse((child) => {
                if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
                    child.material.envMap = environmentMap
                    child.material.needsUpdate = true
                    child.castShadow = true
                    child.receiveShadow = true
                    child.material.roughness = .1
                    child.material.metalness = .1
                }
            })
        }
        /**
         * Environment map
         */
        const environmentMap = cubeTextureLoader.load([
            '/textures/environmentMaps/sky/px.png',
            '/textures/environmentMaps/sky/nx.png',
            '/textures/environmentMaps/sky/py.png',
            '/textures/environmentMaps/sky/ny.png',
            '/textures/environmentMaps/sky/pz.png',
            '/textures/environmentMaps/sky/nz.png'
        ])

        scene.background = environmentMap
        scene.environment = environmentMap

        debugObject.envMapIntensity = .3

        /**
         * Models
         */


        let modelMesh = []
        let videoart = []
        gltfLoader.load(
            '/models/210517_ring.glb',
            (gltf) => {
                const model = gltf.scene
                model.castShadow = true
                model.receiveShadow = true
                model.scale.set(20, 20, 20)
                model.position.set(0, 0.1, -550)
                model.rotation.y = 2.6
                model.traverse((child) => {
                    if (child.isMesh) {
                        objects.push(child)
                        child.castShadow = true
                        child.receiveShadow = true
                        child.reflectivity = 1.0
                        // console.log(child)


                    }
                    if (child.isMesh && child.name === 'Circle008') {
                        objects.push(child)
                        child.castShadow = true
                        child.receiveShadow = true
                        child.visible = false

                    }
                    if (child.name === 'Circle008_1') {
                        // console.log('there!')
                        child.material.map = videoTexture
                        // child.visible = false

                    }
                })

                scene.add(model)
                updateAllMaterials()

            },


        )
       
        gltfLoader2.load(
            '/models/210420_hito.glb',
            (gltf) => {
                const hito = gltf.scene
                hito.castShadow = true
                hito.receiveShadow = true
                hito.scale.set(20, 20, 20)
                hito.position.set(0, 0.1, -550)
                hito.rotation.y = 2.6


                scene.add(hito)
                updateAllMaterials()

            },

        )


        renderer = new THREE.WebGLRenderer({
            antialias: true
        });
        renderer.outputEncoding = THREE.sRGBEncoding;

        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        document.body.appendChild(renderer.domElement);


        window.addEventListener('resize', onWindowResize);

    }

    function onWindowResize() {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);

    }


    if (isMobile == true) {
        window.addEventListener('touchstart', function () {
            clearTimeout(backWard)
        })
    }


    function animate() {
        const clock = new THREE.Clock()
        const elapsedTime = clock.getElapsedTime()
        requestAnimationFrame(animate);

        const time = performance.now();


        ///VIDEO







        raycaster.ray.origin.copy(controls.getObject().position);
        var intersections = raycaster.intersectObjects(objects);
        const onObject = intersections.length > 0;
        const popUpintersections = raycaster.intersectObjects(popUpObjects);
        const popObject = popUpintersections.length < 0;
        const collisionRange = 20
        const delta = (time - prevTime) / 1000;
        let tempVelocity = velocity.clone().multiplyScalar(delta)
        let nextPosition = controls.getObject().position.clone().add(tempVelocity);
        let tooClose = false;
        let playerPosition = controls.getObject().position;
        for (let i = 0; i < objects.length; i++) {
            let object = objects[i];
            let objectDirection = object.position.clone().sub(playerPosition).normalize();
            raycaster.set(nextPosition, objectDirection)
            let directionIntersects = raycaster.intersectObject(objects[i]);

            if (directionIntersects.length > 1.5 && directionIntersects[0].distance < collisionRange) {
                tooClose = true;
                moveForward = false
            }
        }


        for (let i = 0; i < popUpObjects.length; i++) {
            let dataList = popUpObjects[i];
            let objectDirection = dataList.position.clone().sub(playerPosition).normalize();
            raycaster.set(controls.getObject().position, objectDirection)
            let directionIntersects = raycaster.intersectObject(dataList)
            let instructions = document.getElementById('instructions')
            let closeBtn = document.getElementsByClassName('close')

            if (directionIntersects.length > 0) {
                instructions.style.opacity = '0'

                for (const data of Object.keys(dataList)) { //箱配列の中身を順番に処理

                    if (raycaster.intersectObject(popUpObjects[0]).length > 0) { //もし一つ目に当たったら
                        let point1 = document.getElementsByClassName('point-0')
                        point1[0].classList.add('visible')
                        tl.play()

                        break
                    }

                    if (raycaster.intersectObject(popUpObjects[1]).length > 0) {
                        let point2 = document.getElementsByClassName('point-1')
                        point2[0].classList.add('visible')
                        break
                    }
                    if (raycaster.intersectObject(popUpObjects[2]).length > 0) {
                        let point3 = document.getElementsByClassName('point-2')
                        point3[0].classList.add('visible')
                        videocheck()
                        break
                    }

                }

                if (!isMobile == true) {
                    document.exitPointerLock();
                    break
                }
                if (directionIntersects.length > 0 && isMobile == true) {
                    for (let i = 0; i < closeBtn.length; i++) {
                        closeBtn[i].addEventListener('touchstart', function () {
                            moveBackward = true
                        })

                        window.addEventListener('click', function () {
                            moveBackward = false
                        })
                    }
                }

            } else if (moveBackward == true) {
                for (let i = 0; i < point.length; i++) {
                    point[i].classList.remove('visible')
                    videoend()
                }

                controls.lock()
            }
        }



        velocity.x -= velocity.x * 10.0 * delta;
        velocity.z -= velocity.z * 8.0 * delta;
        velocity.y -= 0 * delta; // 100.0 = mass
        direction.z = Number(moveForward);
        direction.x = Number(moveRight) - Number(moveLeft);
        direction.normalize(); // this ensures consistent movements in all directions

        if (moveForward) velocity.z -= direction.z * 500.0 * delta;
        if (moveBackward) velocity.z += 400.0 * delta;
        if (moveLeft || moveRight) velocity.x -= direction.x * 400.0 * delta;

        if (onObject === true) {
            velocity.y = Math.max(0, velocity.y);
            velocity.x = 0;
            velocity.z = Math.max(0, velocity.z);
        }

        for (let i = 0; i < textMesh.length; i++) {
            textMesh[i].rotation.y += 0.008

        }


        controls.moveRight(-velocity.x * delta);
        controls.moveForward(-velocity.z * delta);

        controls.getObject().position.y += (velocity.y * delta);
        if (controls.getObject().position.y < 10) {
            velocity.y = 0;
            controls.getObject().position.y = 10;
            canJump = true;
        }



        prevTime = time;

        renderer.render(scene, camera);

    }